import { ArrowRightIcon, Button, Chip, Typo } from '@dreipol/pusch-components';
import { useMediaQuery } from '@dreipol/t3-react-media';
import { StructuredText } from 'datocms-structured-text-utils/dist/types/types';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatoFilter, DatoResponsiveImage } from 'types/dato';
import { DatoPlugins } from '../../../types/dato-plugins';
import { getArticleRoute, getAuthorDetailRoute } from '../../routes';
import { getArticleFilters, getRubric } from '../../utils/get-filter-by-type';
import { Artifact } from '../artifact/artifact';
import { RecursiveContent } from '../recursive-content/recursive-content';
import classes from './article-card.module.scss';
import { useRouter } from 'next/router';
import { FALLBACK_LOCALE } from 'config';

export type ArticleCardProps = {
  index: number;
  /**
   * Dato image data
   */
  imageData: DatoResponsiveImage;

  /**
   * Article title
   */
  title: string;

  /**
   * Authors of article
   */
  authors: {
    name: string;
    image: DatoResponsiveImage;
    id: string;
    slug: string;
  }[];

  /**
   * Date of creation
   */
  creationDate: string | null;

  /**
   * Short description of article
   */
  teaser: StructuredText<DatoPlugins>;

  /**
   * Reading time of article
   */
  readingTime: number;

  /**
   * Slug
   */
  articleSlug: string;

  /**
   * Category filters
   */
  filters: DatoFilter[];
};

export const ArticleCard = ({
  imageData,
  title,
  authors,
  creationDate,
  teaser,
  readingTime,
  articleSlug,
  filters = [],
}: ArticleCardProps) => {
  const formatedDate = (
    creationDate ? new Date(creationDate) : new Date()
  ).toLocaleDateString('de-CH');
  const { t } = useTranslation();
  const router = useRouter();
  const [isHovering, setHover] = useState(false);
  const showThreeToTwoImage = useMediaQuery(['xs', 'xl', 'xxl']);
  const [isRendered, setIsRendered] = useState(false);
  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <article
      className={classes.root}
      itemScope
      itemType="https://schema.org/Article"
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Artifact
        fill
        variant="appearOnHover"
        className={classes.imageContainer}
        active={isHovering}
        color={getRubric(filters)?.color}
      >
        <Link
          className={classes.imageLink}
          href={getArticleRoute(articleSlug, router.locale ?? FALLBACK_LOCALE)}
        >
          <div className={classes.imageWrapper}>
            <Image
              className={classes.image}
              src={
                showThreeToTwoImage
                  ? imageData.webp ?? ''
                  : imageData.webpSquare ?? ''
              }
              alt={imageData.alt ?? imageData.title ?? ''}
              fill={true}
              loading={'lazy'}
              itemProp={'image'}
            />
          </div>
        </Link>
      </Artifact>

      <div className={classes.middleContainer}>
        <div className={classes.metaInfoContainer}>
          <Typo variant="paragraph2" itemProp={'datePublished'}>
            {formatedDate}
          </Typo>
          {authors.length > 0 && <Typo variant="paragraph2">&middot;</Typo>}
          <div
            className={classes.authorsContainer}
            itemProp="author"
            itemScope
            itemType="https://schema.org/Person"
          >
            {authors.map<React.ReactNode>((author, i) => {
              return (
                <Link
                  key={i}
                  href={getAuthorDetailRoute(
                    author.slug,
                    router.locale ?? FALLBACK_LOCALE,
                  )}
                  itemProp="url"
                >
                  <Button
                    typoVariant="paragraph2"
                    inline
                    disableHover
                    variant="text"
                    className={classes.authorLink}
                  >
                    <span itemProp={'name'}>{author.name}</span>
                    <span className={classes.delimiter}>
                      {i < authors.length - 1 ? ',' : ''}
                    </span>
                  </Button>
                </Link>
              );
            })}
          </div>
        </div>
        <Link
          href={getArticleRoute(articleSlug, router.locale ?? FALLBACK_LOCALE)}
        >
          <Button
            className={classes.title}
            variant={'plain'}
            align={'left'}
            typoVariant={'inherit'}
            inline
          >
            <span itemProp="headline">{title}</span>
          </Button>
        </Link>
        <div className={classes.categoryContainer}>
          {getArticleFilters(filters).map(
            (filter) =>
              filter && (
                <Chip key={filter.id} color={filter.color}>
                  {filter.term}
                </Chip>
              ),
          )}
        </div>
      </div>
      <div className={classes.teaserContainer}>
        <div className={classes.teaserText} itemProp="description">
          <Link
            href={getArticleRoute(
              articleSlug,
              router.locale ?? FALLBACK_LOCALE,
            )}
          >
            <RecursiveContent
              data={teaser}
              paragraphTypoVariant={'paragraph2'}
              typoProps={{ color: 'secondary' }}
            />
          </Link>
        </div>
        <Link
          href={getArticleRoute(articleSlug, router.locale ?? FALLBACK_LOCALE)}
        >
          <Button
            className={classes.toggleArticle}
            variant="plain"
            endAdornment={<ArrowRightIcon />}
          >
            {isRendered &&
              t('general.reading_time_cta', {
                readingTime: readingTime,
              })}
          </Button>
        </Link>
      </div>
    </article>
  );
};
